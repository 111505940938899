import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { useModals } from 'hooks';

import Modal from 'components/Modal/Modal';
import ContactUsLink from 'components/ContactUsLink';

import * as S from './styled';

import { FeedbackModalTemplatePropsType } from './types';

const FeedbackModalTemplate = ({
    withCloseButton,
    withRoundedCorners = true,
    children,
    image,
    subtitle,
    title,
    contactUs,
    dataLocators = {},
    onClose,
    marginTop = 60,
    imageWidth = 48,
    maxWidth,
}: FeedbackModalTemplatePropsType) => {
    const { t } = useTranslation();

    const { closeModal } = useModals();

    const handleClose = () => {
        closeModal();
        onClose?.();
    };

    return (
        <Modal
            onClose={handleClose}
            isOpen
            customStyles={{ padding: `${marginTop}px 16px 16px`, ...(maxWidth && { maxWidth: `${maxWidth}px` }) }}
            withCloseButton={withCloseButton}
            withRoundedCorners={withRoundedCorners}
            dataLocator={dataLocators.body}
        >
            {image?.src && (
                <S.Emoji
                    mt={image.mt}
                    maxWidth={imageWidth}
                    center
                    alt="emoji"
                    src={image.src}
                    mb={24}
                    isForceVisible
                />
            )}
            <Text dataLocator={dataLocators.title} type="h5" text={t(title)} mb={subtitle ? 12 : 28} center />
            {subtitle && <Text dataLocator={dataLocators.subTitle} text={t(subtitle)} mb={24} center />}
            {children}
            {contactUs && <ContactUsLink {...contactUs} mb={12} />}
        </Modal>
    );
};

export default FeedbackModalTemplate;
