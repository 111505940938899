import { all, call, put } from 'redux-saga/effects';

import { checkAllCancelledSubscriptions } from 'store/subscriptions/helpers';
import { cancelSubscription } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { sendAnalyticSubscriptionCancelError } from 'services/analytics';

import { ModalName } from 'components/Modals/types';

import { pollGetSubscriptions } from './pollGetSubscriptions';
import { callUnsubscribe } from './callUnsubscribe';
import { callSuccessCancellingBehaviour } from './callSuccessCancellingBehaviour';
import { callErrorCancellingBehaviour } from './callErrorCancellingBehaviour';

export function* makeSubscriptionCancelling({
    payload: cancellationCandidates,
}: ReturnType<typeof cancelSubscription>) {
    const successCount = { current: 0 };
    const errorCount = { current: [] };

    yield put(
        openModal(ModalName.WithLoaderModal, {
            title: 'subscription.withLoaderModal.cancellingPlan',
            img: null,
        })
    );

    try {
        yield all(
            cancellationCandidates.map((candidate) => call(callUnsubscribe, candidate, successCount, errorCount))
        );
    } catch (error) {
        const [firstSubscription] = cancellationCandidates;

        yield call(sendAnalyticSubscriptionCancelError, firstSubscription.currentSubscription.external_id);
    } finally {
        const successTotal = successCount.current;
        const errorTotal = errorCount.current.length;

        if (errorTotal > 0) {
            yield call(callErrorCancellingBehaviour, { errorCount, cancellationCandidates });
        } else if (successTotal > 0) {
            yield call(pollGetSubscriptions, checkAllCancelledSubscriptions(cancellationCandidates));
            yield call(callSuccessCancellingBehaviour, { cancellationCandidates });
        }
    }
}
